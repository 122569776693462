import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { I18nModule } from '@logic-suite/shared/i18n/i18n.module';
import { TutorialComponent } from './tutorial.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [TutorialComponent],
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule, I18nModule],
  exports: [TutorialComponent],
})
export class TutorialModule {}
