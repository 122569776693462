import { Component, OnInit, Input, ViewChild, TemplateRef, OnDestroy, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LanguageService } from '@logic-suite/shared/i18n/language.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit, OnDestroy {
  @Input() widgetName = 'app';
  @Input() showAsModal = false;
  @ViewChild('videoEl') videoEl!: TemplateRef<HTMLVideoElement>;

  subscriptions: Subscription[] = [];
  languageCode!: string;
  url = '/media/energy';

  constructor(private el: ElementRef, private lang: LanguageService, private modal: MatDialog) {}

  ngOnInit(): void {
    this.subscriptions.push(this.lang.onLangChange.subscribe(lang => (this.languageCode = lang.lang)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onStart() {
    this.modal.open(this.videoEl, { panelClass: 'video-overlay' });
  }

  imageLoadFail() {
    this.el.nativeElement.classList.add('no-video');
    // evt.target.classList.add('no-video');
  }
}
