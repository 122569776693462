<ng-template #videoEl>
  @if (showAsModal) {
    <header>
      <button mat-icon-button [mat-dialog-close]><mat-icon>close</mat-icon></button>
    </header>
  }
  <video
    controls
    [attr.preload]="!showAsModal ? 'none' : null"
    poster="{{ url }}/{{ languageCode }}/{{ widgetName }}.png"
    [attr.autoplay]="showAsModal ? true : null"
    (error)="imageLoadFail()"
  >
    <source src="{{ url }}/{{ languageCode }}/{{ widgetName }}.webm" type="video/webm" />
    <source src="{{ url }}/{{ languageCode }}/{{ widgetName }}.mp4" type="video/mp4" />
    Sorry, your browser doesn't support embedded videos.
  </video>
</ng-template>

@if (!showAsModal) {
  <ng-container *ngTemplateOutlet="videoEl"></ng-container>
} @else {
  <div class="video-placeholder">
    <img src="{{ url }}/{{ languageCode }}/{{ widgetName }}.png" (error)="imageLoadFail()" />
    <span (click)="onStart()"><mat-icon>play_arrow</mat-icon></span>
  </div>
}
